.product-card {
    height: auto;
    margin: 0 auto;
    position: relative;

    width:auto;
    background:#eeeeee;
    border-radius: 16px 16px 16px 16px;
    padding:16px;
    overflow:hidden;
    box-shadow: 5px 5px 5px #CCC;
}

.product-card:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.product-card-content {
    display: flex;
    width: 100%;
}

.product-icon img {
    border-radius: 12px;
    margin-right: 16px;
}

.product-info {
    flex: 1;
    margin-right: 16px;
}

.product-links {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.product-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 16px;
    margin-left: 16px;
}

.website-link {
    text-decoration: none;
    color: #0073e6;
}

.product-hunt-button {
    padding-top: 4px;
}

.learn-more-button {
    background-color: #2E2E2E;
    color: #fff;
    padding: 8px 12px;
    border-radius: 6px;
    text-decoration: none;
    font-weight: bold;
}

.learn-more-button:hover {
    background-color: #005bb5;
}


.start-free-button {
    background-color: #2552cd;
    color: #fff;
    padding: 12px;
    margin-left: 8px;
    margin-top: 8px;
    border-radius: 6px;
    text-decoration: none;
    font-weight: bold;
}

.start-free-button:hover {
    background-color: #2E2E2E;
}

.container {
    max-width: 100px;
    margin: 20px auto
}

.container_content {
    width: 95%;
    height: 80%;
    max-width: 900px;
    margin: 20px auto
}

.container h1 {
    color: black
}

.container h3 {
    margin: 0 0 2px 0;
    color: #646464;
}

.container h4 {
    margin: 0
}

.container p a {
    color: #646464
}

.container ul {
    padding: 0 0 0 30px;
    margin: 10px 0;
}

.container footer {
    background: #f9f9f9;
    border: solid #e5e5e5;
    border-width: 1px 0;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    width: 100%;
}

div.fast_nav {
    text-align: center;
    font-size: 11px;
    overflow: hidden;
}

div.fast_nav li {
    border-left: 1px solid #5c5b5b;
    display: inline;
    padding: 0 7px;
}

div.fast_nav li.first {
    border: none;
}

div.fast_nav li.selected {
    font-weight: bold;
}

div.fast_nav a {
    color: #5c5b5b;
    text-decoration: none;
}

div.fast_nav a:hover {
    text-decoration: underline;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #ddd; /* Adjust color as needed */
    margin-left: 24px;
    margin-top: 16px;
}
